import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, useLocation } from "react-router-dom";
import theme from "./common/mui-packages/src/assets/theme";
import AppContextProvider from "./contexts/AppContextProvider";
import MapLayerContextProvider from "./contexts/MapLayerContextProvider";
import TimeContextProvider from "./contexts/TimeContextProvider";

import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect } from "react";
import FlightMapPage from "./pages/FlightMapPage";
import { LoginPage } from "./pages/LoginPage";
import { PageNotFound } from "./pages/PageNotFound";
import { Paths } from "./routes/paths";
import "./styles/app.style.css";

const App = () => {
  const { pathname } = useLocation();
  // const { isAuth } = useContext(AppContext);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return (
  //         <Route
  //           exact
  //           path={route.route}
  //           element={route.component}
  //           key={route.key}
  //         />
  //       );
  //     }

  //     return null;
  //   });

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <MapLayerContextProvider>
          <AppContextProvider>
            <TimeContextProvider>
              <CssBaseline />
              <Routes>
                {/* {getRoutes(routes)} */}
                <Route path={Paths.map} element={<FlightMapPage />} />
                <Route path={Paths.login} element={<LoginPage />} />
                {/* <Route path={Paths.home} element={<FlightMapPage />} /> */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </TimeContextProvider>
          </AppContextProvider>
        </MapLayerContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
