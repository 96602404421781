/* eslint-disable */
import dayjs from "dayjs";
import React, { useState } from "react";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// Definition for app context props
export interface TimeContextProps {
  startTimeLocal: Date | undefined;
  endTimeLocal: Date | undefined;
  startTimeUtcString: string | undefined;
  endTimeUtcString: string | undefined;
  setTimeRange: (startTime?: Date, endTime?: Date) => void;
}

// Create the app context
export const TimeContext = React.createContext<TimeContextProps>({
  startTimeLocal: undefined,
  endTimeLocal: undefined,
  startTimeUtcString: undefined,
  endTimeUtcString: undefined,
  setTimeRange: (startTime?: Date, endTime?: Date) => {},
});

interface ILocalProps {
  children?: React.ReactNode;
}
type Props = ILocalProps;

const TimeContextProvider: React.FC<Props> = (props) => {
  // Fields
  const contextName: string = "TimeContextProvider";

  const [startTime, setStartTime] = useState<Date | any>();
  const [endTime, setEndTime] = useState<Date | any>();
  const [startTimeUtcString, setStartTimeUtcString] = useState<
    string | undefined
  >(undefined);
  const [endTimeUtcString, setEndTimeUtcString] = useState<string | undefined>(
    undefined
  );

  const datetimeToString = (datetime: any): string => {
    let date = datetime.utc().format("YYYYMMDDHHMM"); // display
    // if datetime is before May 31st 2023, set it to May 31st 2023
    if (datetime.isBefore("2023-05-31")) {
      date = "202305311200";
    }
    return date;
  };

  const handleTimeRangeChange = (
    start: Date | undefined,
    end: Date | undefined
  ) => {
    if (start) {
      setStartTime(start);
      setStartTimeUtcString(datetimeToString(dayjs(start)));
    }
    if (end) {
      setEndTime(end);
      setEndTimeUtcString(datetimeToString(dayjs(end)));
    }
  };

  return (
    <TimeContext.Provider
      value={{
        startTimeLocal: startTime,
        endTimeLocal: endTime,
        startTimeUtcString,
        endTimeUtcString,
        setTimeRange: handleTimeRangeChange,
      }}
    >
      {props.children}
    </TimeContext.Provider>
  );
};

export default TimeContextProvider;
