/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// @mui material components
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
// import MKAvatar from "components/MKAvatar";
// import MKTypography from "components/MKTypography";
import MKBox from "../common/mui-packages/src/components/MKBox";

// Material Kit 2 PRO React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultFooter from "../common/mui-packages/src/examples/Footers/DefaultFooter";

// VirtualReality page components
// import Emails from "pages/Extra/VirtualReality/components/Emails";
// import MediaPlayer from "pages/Extra/VirtualReality/components/MediaPlayer";
// import Messages from "pages/Extra/VirtualReality/components/Messages";
// import TodoCard from "pages/Extra/VirtualReality/components/TodoCard";
// import TodoList from "pages/Extra/VirtualReality/components/TodoList";
import StartPage from "./StartPage";
// Routes
// import footerRoutes from "footer.routes";
// import routes from "routes";
import Portal from "@mui/material/Portal";
import footerRoutes from "../routes/footer.routes";
// Images
// import sunCloud from "assets/images/small-logos/icon-sun-cloud.png";
// import team1 from "assets/images/team-1.jpg";
// import bgImage from "assets/images/vr-bg.jpg";
import FlightDateTimeBar from "../common/FlightDateTimeBar";

function Flights() {
  return (
    <React.Fragment>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "dark",
        }}
      /> */}
      <FlightDateTimeBar />
      <MKBox
        component="header"
        minHeight="80vh"
        width="100%"
        // sx={{
        //   // backgroundImage: ({
        //   //   functions: { linearGradient, rgba },
        //   //   palette: { gradients },
        //   // }) =>
        //   //   `${linearGradient(
        //   //     rgba(gradients.dark.main, 0.6),
        //   //     rgba(gradients.dark.state, 0.6)
        //   //   )}, url(${bgImage})`,
        //   // backgroundSize: "cover",
        //   // backgroundPosition: "center",
        //   // display: "grid",
        //   // placeItems: "center",
        // }}
      >
        <Portal>
          <StartPage />
        </Portal>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </React.Fragment>
  );
}

export default Flights;
