/* eslint-disable */ 
import dayjs from "dayjs";
import React, { useState } from "react";
import { ANIMATION_SPEED } from "../components/FlightMap.types";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

// Definition for app context props
export interface MapLayerContextProps {
  is3DPointLayer: boolean;
  set3DPointLayer: (enabled: boolean) => void;
  isAnimationEnabled: boolean;
  setAnimationEnabled: (enabled: boolean) => void;
  animationSpeed: number;
  setAnimationSpeed: (speed: number) => void;
}

// Create the app context
export const MapLayerContext = React.createContext<MapLayerContextProps>({
  is3DPointLayer: false,
  set3DPointLayer: (enabled: boolean) => {},
  isAnimationEnabled: false,
  setAnimationEnabled: (enabled: boolean) => {},
  animationSpeed: ANIMATION_SPEED,
  setAnimationSpeed: (speed: number) => {},
  
});

interface ILocalProps {
  children?: React.ReactNode;
}
type Props = ILocalProps;

const MapLayerContextProvider: React.FC<Props> = (props) => {
  // Fields
  const contextName: string = "MapLayerContextProvider";

  const [is3DPointLayer, set3DPointLayer] = useState(true);
  const [isAnimationEnabled, setAnimationEnabled] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(ANIMATION_SPEED);

  return (
    <MapLayerContext.Provider
      value={{
        is3DPointLayer,
        set3DPointLayer,
        isAnimationEnabled,
        setAnimationEnabled,
        animationSpeed,
        setAnimationSpeed,
      }}
    >
      {props.children}
    </MapLayerContext.Provider>
  );
};

export default MapLayerContextProvider;
