/*eslint-disable */
import { LayerDataSource } from "@deck.gl/core/typed";
import {
  LineLayerProps,
  PathLayerProps,
  PointCloudLayerProps,
} from "@deck.gl/layers/typed";
import axios from "axios";
import { GeoJSON } from "geojson";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// import { ToastContainer, TypeOptions, toast } from "react-toastify";
import { TimeContext } from "../contexts";
import { MapLayerContext } from "../contexts/MapLayerContextProvider";
import { CoorData, GenLayer, IFlightMap } from "./FlightMap.types";
import { FlightMapInner } from "./FlightMapInner";

// const types = ["success", "info", "warning", "error"];

// export type NotificationType = "success" | "info" | "warning" | "error";
export enum NotificationType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export const FlightMap: React.FC<IFlightMap> = ({
  icaos,
  layerType,
  stateVectors,
}) => {
  const layersRef = useRef<GenLayer[]>();
  const [layers, setLayers] = useState<GenLayer[]>();
  const { startTimeUtcString, endTimeUtcString } = useContext(TimeContext);
  const { is3DPointLayer } = useContext(MapLayerContext);

  const tryLayerUpdate = useCallback(() => {
    if (!layers) {
      setLayers(layersRef.current);
    } else if (layersRef.current?.length !== layers?.length) {
      setLayers(layersRef.current);
    }
  }, [layersRef, layers]);

  useEffect(() => {
    const int = setInterval(tryLayerUpdate, 1000);
    return () => {
      clearInterval(int);
    };
  }, [tryLayerUpdate, layerType]);

  // const addNotification = (msg: string, type: NotificationType) => {
  //   // use a random type of notification
  //   toast(msg, {
  //     type: type as TypeOptions,
  //   });
  // };

  // useEffect(() => {
  //   addNotification(
  //     "Successfully fetched plane data",
  //     NotificationType.SUCCESS
  //   );
  // }, []);

  const fetchPath = useCallback(
    async (icao: string): Promise<void> => {
      const genLineLayer = (
        icao: string,
        data: LayerDataSource<CoorData[]>
      ): LineLayerProps => {
        let newLineLayerProps: LineLayerProps = {
          id: `line-layer-${icao}`,
          data,
          getWidth: 2,
          getSourcePosition: (d) => d.from.coordinates,
          getTargetPosition: (d) => d.to.coordinates,
          getColor: [255, 140, 0],
          opacity: 0.8,
        };

        return newLineLayerProps;
      };

      return await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVICE_URL}/path?icao=${icao}&start=${startTimeUtcString}&end=${endTimeUtcString}`,
      }).then(function (response) {
        if (
          response?.data.coordinates &&
          response?.data.coordinates.length > 0
        ) {
          let data = response.data;

          let line: CoorData[] = [];

          for (let i = 0; i < data.coordinates.length - 1; i++) {
            if (
              data.coordinates[i][0] === null ||
              data.coordinates[i + 1][0] === null ||
              data.coordinates[i][1] === null ||
              data.coordinates[i + 1][1] === null
            ) {
              continue;
            } else {
              line.push({
                from: {
                  coordinates: [
                    data.coordinates[i][1] === "ground"
                      ? 0
                      : data.coordinates[i][1],
                    data.coordinates[i][0] === "ground"
                      ? 0
                      : data.coordinates[i][0],
                  ],
                },
                to: {
                  coordinates: [
                    data.coordinates[i + 1][1] === "ground"
                      ? 0
                      : data.coordinates[i + 1][1],
                    data.coordinates[i + 1][0] === "ground"
                      ? 0
                      : data.coordinates[i + 1][0],
                  ],
                },
              });
            }
          }

          if (line.length > 0) {
            if (!layersRef?.current) {
              layersRef.current = [
                { id: icao, props: genLineLayer(icao, line) },
              ];
            } else {
              layersRef.current = layersRef.current.concat({
                id: icao,
                props: genLineLayer(icao, line),
              });
            }
          }
        }
      });
    },
    [startTimeUtcString, endTimeUtcString, layersRef]
  );

  /**
   * Fetches the data to generated the PathLayer
   */
  const fetchPathLayer = useCallback(
    async (icao: string): Promise<void> => {
      const genPathLayer = (icao: string, d: any): PathLayerProps => {
        /**
         * Data format:
         * [
         *   {
         *     path: [[-122.4, 37.7], [-122.5, 37.8], [-122.6, 37.85]],
         *     name: 'Richmond - Millbrae',
         *     color: [255, 0, 0]
         *   },
         *   ...
         * ]
         */

        return {
          id: `path-layer-${icao}`,
          data: [
            {
              path: d,
              type: "major",
              name: icao,
              color: [255, 0, 0],
            },
          ],
          pickable: true,
          widthScale: 20,
          widthMinPixels: 2,
          getPath: (d) => [d.path[1], d.path[0]],
          getColor: [255, 140, 0],
          getWidth: (d) => 5,
        };
      };

      return await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVICE_URL}/pathlayer?icao=${icao}&start=${startTimeUtcString}&end=${endTimeUtcString}`,
      }).then(function (response) {
        if (
          response?.data.coordinates &&
          response?.data.coordinates.length > 0
        ) {
          if (!layersRef?.current) {
            layersRef.current = [
              {
                id: icao,
                props: genPathLayer(icao, response.data.coordinates),
              },
            ];
          } else {
            layersRef.current = layersRef.current.concat({
              id: icao,
              props: genPathLayer(icao, response.data.coordinates),
            });
          }
        }
      });
    },
    [startTimeUtcString, endTimeUtcString, layersRef]
  );

  // const fetchData = useCallback(async (icao: string) =>{
  //   if (isPathLayer){
  //     return fetchPathLayer(icao);
  //   } else {
  //     return fetchPath(icao);
  //   }
  // }, [fetchPathLayer, fetchPath, isPathLayer]);

  useEffect(() => {
    if (
      stateVectors?.time &&
      stateVectors?.states &&
      layerType === "PointCloudLayer"
    ) {
      layersRef.current = [
        {
          id: `PointCloud-${stateVectors.time}`,
          props: {
            id: "PointCloudLayer",
          } as PointCloudLayerProps,
        },
      ];
    }
    setLayers(layersRef.current);
  }, [stateVectors.time, stateVectors.states, layerType]);

  const [mBaseJson, setMBaseJson] = useState<GeoJSON>();

  useEffect(() => {
    const fetchMilBases = () => {
      fetch(`${process.env.REACT_APP_SERVICE_URL}/geodata/bases`)
        .then((response: any) => response.json())
        .then((data: any) => {
          if (data) {
            setMBaseJson(data);
          }
        })
        .catch((error) => console.error("Error:", error));
    };
    fetchMilBases();

    // return () => setLoading(false);
  }, []);

  const mapStyle = useMemo(() => {
    // const simple =
    // "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json";
    const dark =
      "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";

    return dark;
  }, []);

  return (
    <>
      {/* <div
        className="map-container"
        style={{ zIndex: 1000, position: "absolute" }}
      >
        <NotificationCenter />
        <ToastContainer position="bottom-right" newestOnTop />
      </div> */}

      <FlightMapInner
        time={stateVectors?.time}
        genLayers={layers}
        layerType={layerType}
        chunks={stateVectors.chunks}
        states={stateVectors.states}
        names={stateVectors.names}
        is3DPointLayerEnabled={is3DPointLayer}
        mBaseJson={mBaseJson}
        mapStyle={mapStyle}
      />
    </>
  );
};
