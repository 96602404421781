/* eslint-disable */
import React, { useState } from "react";

// Definition for app context props
export interface AppContextProps {
  hasConnectionErrors: boolean;
  isAuth: boolean;
  checkAuth: (user: string, pass: string) => boolean;
}

// Create the app context
export const AppContext = React.createContext<AppContextProps>({
  hasConnectionErrors: false,
  isAuth: false,
  checkAuth: () => false
});

interface ILocalProps {
  children?: React.ReactNode;
}
type Props = ILocalProps;

const AppContextProvider: React.FC<Props> = (props) => {
  // Fields
  const contextName: string = "AppContextProvider";

  // States
  const [hasConnectionErrors, setConnectionErrors] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
 
  const checkAuth = (user: string, pass: string) => {
    if (user == "foo" && pass == "bar") {
      setIsAuth(true);
      return true;
    } else {
      setIsAuth(false);
      return false
    }
  };




  return (
    <AppContext.Provider
      value={{
        hasConnectionErrors: hasConnectionErrors,
        isAuth,
        checkAuth}}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
