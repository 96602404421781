/* eslint-disable */ 
import {
  COORDINATE_SYSTEM,
  LayersList,
  PickingInfo,
} from "@deck.gl/core/typed";
import { GeoJsonLayer, PointCloudLayer } from "@deck.gl/layers/typed";
import DeckGL from "@deck.gl/react/typed";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import maplibregl from "maplibre-gl";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Map from "react-map-gl";
import { PlaneInfoCard } from "../common/PlaneInfoCard";
import { MapLayerContext } from "../contexts";

import Box from "@mui/material/Box";
import { IStateVector } from "../types";
import {
  ANIMATION_SPEED,
  BLUE_COLOR_GRADIENT,
  GREEN_COLOR_GRADIENT,
  IFlightMapProps,
  RED_COLOR_GRADIENT,
  SELECTED_OPACITY,
  TooltipContent,
  UNSELECTED_OPACITY,
  VIOLET_COLOR_GRADIENT,
  delay,
} from "./FlightMap.types";

export const FlightMapInner: React.FC<IFlightMapProps> = React.memo(
  ({
    time,
    chunks,
    states,
    mBaseJson,
    mapStyle,
  }) => {
    const [buttonState, setButtonState] = useState<any>(() => []);
    const {isAnimationEnabled, animationSpeed} = useContext(MapLayerContext);

    const handleFormat = (
      event: React.MouseEvent<HTMLElement>,
      newFormats: string[]
    ) => {
      let s = new Set(buttonState);
      if (!s.has("animate") && s.has("pause")) {
        setButtonState([]);
      } else {
        setButtonState(newFormats);
      }
    };

    const getPlaneLayerTooltip = useCallback((info: PickingInfo) => {
      if (!info?.object) {
        return null;
      }

      return `${
        info?.object?.callsign ? info?.object?.callsign : "Callsign N/A"
      }
      ${info?.object?.longname ? info?.object?.longname : "Type n/a"}
      ${
        info.object.geo_altitude
          ? info.object.geo_altitude < 0
            ? "ground"
            : info.object.geo_altitude.toFixed(2) + " ft"
          : "Altitude N/A"
      }`;
    }, []);

    const getMilbaseLayerTooltip = useCallback((info: PickingInfo) => {
      if (!info?.object) {
        return null;
      }

      return `${info?.object.properties?.siteName}`;
    }, []);

    /**
     * Returns the tooltip content for the given picking info
     */

    const getTooltip = useCallback(
      (info: PickingInfo): TooltipContent => {
        if (!info) {
          return null;
        }
        var isCallerMilBaseLayer =
          info.layer && info?.layer.props.id === "milBaseLayer";

        return isCallerMilBaseLayer
          ? getMilbaseLayerTooltip(info)
          : getPlaneLayerTooltip(info);
      },
      [getPlaneLayerTooltip, getMilbaseLayerTooltip]
    );

    const getSelectedPlaneInfo = (
      icao: string,
      states: Array<IStateVector>
    ) => {
      let planeInfo = states.find((state) => state.icao24 === icao);
      return planeInfo;
    };

    const [hoveredIcao, setHoveredIcao] = useState<string>();
    const [clickedIcao, setClickedIcao] = useState<string>();

    const [hoverInfo, setHoverInfo] = useState<PickingInfo>();
    const [clickInfo, setClickInfo] = useState<PickingInfo>();

    const setHoverCb = useCallback(
      (info: PickingInfo) => {
        if (!info?.object) {
          setHoverInfo(info);
          setHoveredIcao(info?.object?.icao24);
        }

        // Clicked paths should take precedence over hovered paths
        if (!clickedIcao) {
          setHoverInfo(info);
          setHoveredIcao(info?.object?.icao24);
        }
      },
      [clickedIcao]
    );

    const setClickCb = useCallback((info: PickingInfo, icao: string) => {
      // console.log("setClickCb", info);
      setClickInfo(info);
      setClickedIcao(icao);
    }, []);



    const [currentStateVector, setCurrentStateVector] = useState<
      Array<IStateVector> | undefined
    >(undefined);
    const [slice, setSlice] = useState<number>(0);

    useEffect(() => {
      if (!isAnimationEnabled) {
        setCurrentStateVector(states);
      } else {
        if (slice >= 0 && isAnimationEnabled && chunks && chunks[slice]) {
          let nextLower: number;
          let nextUpper: number;

          if (slice > 0) {
            nextLower = chunks[slice - 1][1];
            nextUpper = chunks[slice][0];
          } else {
            nextLower = 0;
            nextUpper = chunks[slice][0];
          }
          let nextStateVectorStates = states?.slice(nextLower, nextUpper);
          // let nextStateVectorNames = names?.slice(nextLower, nextUpper);
          let nextStateVector: Array<IStateVector> = nextStateVectorStates;
          //wait 1 second before next animation
          setCurrentStateVector(nextStateVector);

          let nextSlice: number;

          if (slice >= chunks.length - 1) {
            nextSlice = 0;
          } else {
            nextSlice = slice + 1;
          }

          //add a 2 second delay before next animation

          delay(ANIMATION_SPEED).then(() => {
            setSlice(nextSlice);
          });
        }
      }
    }, [slice, isAnimationEnabled, chunks, states, time]);

    interface IColorMapper {
      alt: number | null;
      colorMap?: [number, number, number, number][];
      l2d?: boolean; // Color opacity grows with altitude
    }

    const mapAltToColor = useCallback(
      ({ alt, colorMap = VIOLET_COLOR_GRADIENT, l2d = true }: IColorMapper) => {
        if (alt === null) {
          return colorMap[l2d ? 0 : 4];
        } else if (alt < 10000) {
          return colorMap[l2d ? 0 : 4];
        } else if (alt < 20000) {
          return colorMap[l2d ? 1 : 3];
        } else if (alt < 30000) {
          return colorMap[l2d ? 2 : 2];
        } else if (alt < 40000) {
          return colorMap[l2d ? 3 : 1];
        } else return colorMap[l2d ? 4 : 0];
      },
      [VIOLET_COLOR_GRADIENT]
    );

    const mapAltToRedColor = useCallback(
      (
        alt: number | null,
        icao24: string
      ): [number, number, number, number] => {
        let newColor = mapAltToColor({ alt, colorMap: RED_COLOR_GRADIENT });
        if (hoveredIcao) {
          return [
            ...(newColor.slice(0, 3) as [number, number, number]),
            hoveredIcao === icao24 ? SELECTED_OPACITY : UNSELECTED_OPACITY,
          ];
        } else {
          return newColor as [number, number, number, number];
        }
      },
      [mapAltToColor, hoveredIcao]
    );

    const mapAltToGreenColor = useCallback(
      (
        alt: number | null,
        icao24: string
      ): [number, number, number, number] => {
        let newColor = mapAltToColor({ alt, colorMap: GREEN_COLOR_GRADIENT });

        if (hoveredIcao) {
          return [
            ...(newColor.slice(0, 3) as [number, number, number]),
            hoveredIcao === icao24 ? SELECTED_OPACITY : UNSELECTED_OPACITY,
          ];
        } else {
          return newColor as [number, number, number, number];
        }
      },
      [mapAltToColor, hoveredIcao]
    );

    const mapAltToBlueColor = useCallback(
      (
        alt: number | null,
        icao24: string
      ): [number, number, number, number] => {
        let newColor = mapAltToColor({ alt, colorMap: BLUE_COLOR_GRADIENT });
        if (hoveredIcao) {
          return [
            ...(newColor.slice(0, 3) as [number, number, number]),
            hoveredIcao === icao24 ? SELECTED_OPACITY : UNSELECTED_OPACITY,
          ];
        } else {
          return newColor as [number, number, number, number];
        }
      },
      [mapAltToColor, hoveredIcao]
    );

    const pointLayerDefault = useMemo(() => {
      // console.log("pointLayerDefault", currentStateVector);
      if (!currentStateVector) return;

      return new PointCloudLayer({
        data: currentStateVector,
        onHover: (info) => setHoverCb(info),
        onClick: (info) => {
          setClickCb(info, info?.object?.icao24);
        },
        id: "PointCloudLayer-default",
        getColor: (value: IStateVector): [number, number, number, number] => {
          let newColor = mapAltToColor({
            alt: value.geo_altitude ?? 0,
            colorMap: VIOLET_COLOR_GRADIENT,
          });
          if (hoveredIcao || clickedIcao) {
            return [
              ...(newColor.slice(0, 3) as [number, number, number]),
              clickedIcao === value?.icao24 || hoveredIcao === value?.icao24
                ? 255
                : 20,
            ];
          } else {
            return newColor as [number, number, number, number];
          }
        },
        updateTriggers: {
          getColor: [hoveredIcao, clickedIcao],
        },
        getNormal: [0, 0, 1],
        getPosition: (d: IStateVector) => [
          d.longitude ?? 0,
          d.latitude ?? 0,
          d.geo_altitude === null || d.geo_altitude === -1
            ? 0
            : d.geo_altitude * 10,
        ],
        material: true,
        // opacity: 0.8,
        pointSize: 5,
        autoHighlight: true,
        coordinateOrigin: [-0.01, -0.01, -0.01],
        coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
        highlightColor: [
          VIOLET_COLOR_GRADIENT[4][0],
          VIOLET_COLOR_GRADIENT[4][1],
          VIOLET_COLOR_GRADIENT[4][2],
          255,
        ],
        pickable: true,
        visible: true,
        transitions: {
          getColor: {
            duration: animationSpeed,
            type: "interpolation",
          },
          getPosition: {
            duration: animationSpeed,
            type: "interpolation",
          },
        },
      });
    }, [
      currentStateVector,
      setHoverCb,
      setClickCb,
      mapAltToColor,
      animationSpeed,
      hoveredIcao,
      clickedIcao,
    ]);



    const milBaseLayer = useMemo(() => {
      if (!mBaseJson) return;
      return new GeoJsonLayer({
        id: "milBaseLayer",
        data: mBaseJson,
        extruded: true,
        filled: true,
        getElevation: 10000,
        getFillColor: [153, 255, 102, 80], // [160, 160, 180, 120],
        autoHighlight: true,
        highlightColor: [153, 255, 102, 160],
        getLineWidth: 20,
        getPointRadius: 4,
        getText: (f: any) => f.properties.featureName,
        getTextSize: 16,
        lineWidthMinPixels: 2,
        pointRadiusUnits: "pixels",
        pointType: "circle+text",
        stroked: false,
        opacity: 1,
        pickable: true,
        visible: true,
      });
    }, [mBaseJson]);

    /**
     * Point Layer generation
     * 1. Generate all layers regardless of settings
     * 2. set non-configured layers to not visible
     */
    const layers: LayersList = useMemo(() => {
      return [
        milBaseLayer,
        pointLayerDefault,
      ];
    }, [
      pointLayerDefault,
      milBaseLayer,
    ]);


    const onUnClickIcao = useCallback(() => {
      setClickedIcao("");
      setClickInfo(undefined);
    }, []);

    return (
        <React.Fragment>

 
              {isAnimationEnabled && currentStateVector && (
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      position: "absolute",
                      top: 80,
                      right: 20,
                      zIndex: 1000,
                    }}
                  >
                    Time:
                    {dayjs(currentStateVector[0].time_position).format(
                      "dddd, MMMM D, YYYY h:mm A"
                    )}
                  </Typography>
                </Box>
              )}
       
              {clickInfo && (
                <PlaneInfoCard
                  icao24={clickInfo?.object?.icao24}
                  callsign={clickInfo?.object?.callsign}
                  name={clickInfo?.object?.longname}
                  onClose={onUnClickIcao}
                />
              )}
              <DeckGL
                layers={layers}
                initialViewState={{
                  longitude: -98.4,
                  latitude: 39.74,
                  zoom: 5,
                  maxZoom: 20,
                  pitch: 30,
                }}
                controller={true}
                getTooltip={getTooltip}
              >
                <Map
                  reuseMaps
                  mapLib={maplibregl}
                  mapStyle={mapStyle}
                  styleDiffing={true}
                />
              </DeckGL>
 

      </React.Fragment>
    );
  }
);
