/* eslint-disable */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";
import { MapLayerContext, TimeContext } from "../contexts";

// @mui material components
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";

import Switch from "@mui/material/Switch";

import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// Material Kit 2 PRO React components
// import MKBox from "../../../../components/MKBox";
// import MKInput from "../../../../components/MKInput";
// import MKTypography from "../../../../components/MKTypography";
// // import MKDatePicker from "../../../../components/MKDatePicker";
// import MKButton from "../../../../components/MKButton";
import MKButton from "./mui-packages/src/components/MKButton";
// import StartRoundedIcon from "@mui/icons-material/StartRounded";
import EastIcon from "@mui/icons-material/East";
import Stack from "@mui/material/Stack";
import MKBox from "./mui-packages/src/components/MKBox";
import MKDatePicker from "./mui-packages/src/components/MKDatePicker";

function FlightDateTimePicker() {
  const {
    startTimeLocal,
    endTimeLocal,
    startTimeUtcString,
    endTimeUtcString,
    setTimeRange,
  } = useContext(TimeContext);
  const {
    is3DPointLayer,
    set3DPointLayer,
    isAnimationEnabled,
    setAnimationEnabled,
  } = useContext(MapLayerContext);

  const onStartChange = (value) => {
    console.log(value);
    let d = new Date(value);
    if (value) {
      setTimeRange(d, undefined);
    }
  };

  const onEndChange = (value) => {
    console.log(value);

    let d = new Date(value);
    if (d) {
      setTimeRange(undefined, d);
    }
  };

  const setHourTimeRange = (hours) => {
    let now = dayjs();
    let minutes = hours * 60;
    let lastHour = now.subtract(minutes, "minutes");
    setTimeRange(lastHour.toDate(), now.toDate());
  };

  return (
    <MKBox component="section">
      <Stack direction={"row"} alignContent={"center"} spacing={1}>
        <Stack direction={"row"} spacing={0}>
          <MKDatePicker
            options={{
              enableTime: true,
              dateFormat: "D, M d h:i K",
              fontSize: "large",
            }}
            value={startTimeLocal}
            onChange={onStartChange}
            defaultValue={startTimeUtcString}
          />
          <Stack>
            <EastIcon fontSize={"large"} />
          </Stack>

          <MKDatePicker
            options={{
              enableTime: true,
              dateFormat: "D, M d h:i K",
            }}
            value={endTimeLocal}
            onChange={onEndChange}
            defaultValue={endTimeUtcString}
          />
        </Stack>

        <MKBox sx={{ zIndex: 1000 }}>
          <Stack direction="row" spacing={2}>
            <MKButton
              variant="contained"
              onClick={() => setHourTimeRange(1)}
              color={"dark"}
              size="small"
            >
              Last Hour
            </MKButton>
            <MKButton variant="contained" onClick={() => setHourTimeRange(6)}>
              Last 6 Hours
            </MKButton>
            <MKButton variant="contained" onClick={() => setHourTimeRange(12)}>
              Last 12 Hours
            </MKButton>
            <MKButton variant="contained" onClick={() => setHourTimeRange(24)}>
              Last 24 Hours
            </MKButton>
            <FormControlLabel
              control={
                <Switch
                  checked={is3DPointLayer}
                  onChange={() => set3DPointLayer(!is3DPointLayer)}
                />
              }
              label="3D Mode"
              // icon={<ThreeDRotationIcon />}
            />
          </Stack>
        </MKBox>

        <MKBox sx={{ zIndex: 1000 }}>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAnimationEnabled}
                  onChange={() => setAnimationEnabled(!isAnimationEnabled)}
                />
              }
              label="Animate"
            />
          </Stack>
        </MKBox>
      </Stack>
    </MKBox>
  );
}

export default FlightDateTimePicker;
