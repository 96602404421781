/* eslint-disable */
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Todo - Add real plane JPG fetch from API\
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useRef, useState } from "react";

export const DEFAULT_IMAGE = "/hagmeister.jpg";
export enum PlaneTitleEnum {
  None = "None",
  HexID = "HexID",
  Callsign = "Callsign",
  PlaneType = "PlaneType",
}

interface IPlaneInfoCardInnerProps {
  title?: string;
  titleType: PlaneTitleEnum;
  picture?: string; // Store in DB
  description?: string; //Todo
  loading: boolean;
  onClose: () => void;
}

export const PlaneInfoCardInner: React.FC<IPlaneInfoCardInnerProps> = ({
  title,
  description,
  picture,
  onClose,
  loading,
}) => {
  return (
    <Card sx={{ position: "absolute", top: 10, right: 10, zIndex: 1000 }}>
      <Stack direction="row-reverse" spacing={1}>
        <IconButton onClick={onClose} size="medium">
          <CloseIcon />
        </IconButton>
      </Stack>

      <React.Fragment>
        {loading ? (
          <Skeleton
            variant="rectangular"
            // width=
            height={250}
            animation="wave"
          />
        ) : picture ? (
          <CardMedia
            sx={{ height: 250 }}
            image={picture}
            title="Plane Picture"
          />
        ) : (
          <CardMedia
            sx={{ height: 250 }}
            image={DEFAULT_IMAGE}
            title="Plane Picture"
          />
        )}
      </React.Fragment>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Stack direction="column" spacing={2}>
          <Button variant="outlined" size="medium">
            Recent Flights
          </Button>
          <Button variant="outlined" size="medium">
            Wiki link
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export interface IPlaneInfoCardProps {
  icao24: string;
  callsign: string | undefined;
  name: string | undefined;
  onClose: () => void;
}

export const PlaneInfoCard: React.FC<IPlaneInfoCardProps> = ({
  icao24,
  callsign,
  name,
  onClose,
}) => {
  const [loadingImg, setLoadingImg] = useState<boolean>(true);
  const [planeImgSrc, setPlaneImgSrc] = useState<string | undefined>(undefined);
  const fetchingImgRef = useRef<boolean>(false);

  // const [loadingName, setLoadingName] = useState<boolean>(true);
  // const [planeName, setPlaneName] = useState<string | undefined>(undefined);
  // const fetchingNameRef = useRef<boolean>(false);

  useEffect(() => {
    const fetchImg = () => {
      setLoadingImg(true);
      fetchingImgRef.current = true;

      fetch(
        `${process.env.REACT_APP_SERVICE_URL}/planeinfo/imgurl?icao=${icao24}`
      )
        .then((response: any) => response.json())
        .then((data: any) => {
          if (data.imgurl) {
            setPlaneImgSrc(data.imgurl);
          } else {
            setPlaneImgSrc(undefined);
          }
        })
        .catch((error) => console.error("Error:", error))
        .finally(() => {
          fetchingImgRef.current = false;
          setLoadingImg(false);
        });
    };
    if (icao24 && fetchingImgRef.current != true) fetchImg();

    return () => {
      setLoadingImg(false);
      fetchingImgRef.current = false;
    };
  }, [icao24]);

  // useEffect(() => {

  //   const fetchName = () => {
  //     setLoadingName(true);
  //     fetchingNameRef.current = true;
  //     fetch(`${process.env.REACT_APP_SERVICE_URL}/planeinfo/name?icao=${icao24}`)
  //       .then((response: any) => response.json())
  //       .then((data: any) => {
  //         console.log(data);
  //         if (data.name) {

  //           setPlaneName(data.name);
  //         } else {
  //           setPlaneName(undefined);
  //         }

  //       })
  //       .catch((error) => console.error("Error:", error))
  //       .finally(() => {
  //         setLoadingName(false);

  //         fetchingNameRef.current = false;
  //       });
  //   };
  //   if (icao24 && fetchingNameRef.current != true) {
  //     fetchName();
  //   }

  //   return () => {
  //     setLoadingName(false);
  //     fetchingNameRef.current = false;
  //   }
  // }, [icao24]);



  // const description =
  //   "This is generic text I am writing to describe the plane.\n I need to figure out how to get the description for each Plane\n";

  let title: string;
  let titleType: PlaneTitleEnum = PlaneTitleEnum.None;
  let description: string | undefined =
    "This is generic text I am writing to describe the plane.\n I need to figure out how to get the description for each Plane\n";
  if (name) {
    console.log("name found", name);
    titleType = PlaneTitleEnum.PlaneType;
    title = name;
  } else if (callsign) {
    titleType = PlaneTitleEnum.Callsign;
    title = callsign;
  } else {
    titleType = PlaneTitleEnum.HexID;
    title = icao24;
  }

  if (!name && !planeImgSrc) {
    titleType = PlaneTitleEnum.None;
    title = "Hagmeister";
    description =
      "The information on this plane can't be found. \n \n However, it's bath time for Hagmeister and he is very very dirty";
  }
  return (
    <PlaneInfoCardInner
      title={title}
      titleType={titleType}
      onClose={onClose}
      description={description}
      picture={planeImgSrc}
      loading={loadingImg}
    />
  );
};
