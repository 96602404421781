/* eslint-disable */

import React, { useCallback, useEffect, useRef, useState } from "react";

import { FlightMap } from "../components/FlightMap";
import { IStateVector, IStateVectorData } from "../types";

interface ILocalProps {
  startTime?: string;
  endTime?: string;
}

export const MapView: React.FC<ILocalProps> = ({ startTime, endTime }) => {
  const [allIcaos, setAllIcaos] = useState<Array<string | undefined>>([]);
  const [stateVectors, setStateVectors] = useState<IStateVectorData>({
    time: Date.now(),
    states: [],
    chunks: [],
  });
  const loading = useRef<boolean>();

  document.body.style.overflow = "hidden";

  const mapRawStateVectorData = useCallback((rawDataList: any) => {
    // console.log("mapRawStateVectorData", rawDataList);
    const data: IStateVectorData = {
      time: rawDataList.time,
      states: [],
      names: rawDataList?.names,
      chunks: [],
    };

    if (!rawDataList.states) return data;

    let i = 0;
    //compare the timestamps, if not equal to prev, then add boundary index
    let tstamp: any = rawDataList?.Time;

    for (var rawData of rawDataList.states) {
      /**
     *  A state vector consists of:
                         Index Property        Type    Description
       "ac96b8",          0     icao24          string  Unique ICAO 24-bit address of the transponder in hex string representation.
       "AAL1042 ",        1     callsign        string  Callsign of the vehicle (8 chars). Can be null if no callsign has been received.
       "United States",   2     origin_country  string  Country name inferred from the ICAO 24-bit address.
       1526690760,        3     time_position   int     Unix timestamp (seconds) for the last position update.
       1526690760,        4     last_contact    int     Unix timestamp (seconds) for the last update in general.
       -77.226,           5     longitude       float   WGS-84 longitude in decimal degrees.
       35.5134,           6     latitude        float   WGS-84 latitude in decimal degrees.
       9685.02,           7     geo_altitude    float   Geometric altitude in meters.
       false,             8     on_ground       boolean Boolean value which indicates if the position was retrieved from a surface position report.
       219.47,            9     velocity        float   Velocity over ground in m/s.
       232.62,            10    heading         float   Heading in decimal degrees clockwise from north (i.e. north=0°).
       -4.88,             11    vertical_rate   float   Vertical rate in m/, positive for climbing, negative for descending.
       null,              12    sensors         int[]   IDs of the receivers which contributed to this state vector.
       10187.94,          13    baro_altitude   float   Barometric altitude in meters.
       "3517",            14    squawk          string  The transponder code aka Squawk.
       false,             15    spi             boolean Whether flight status indicates special purpose indicator.
       0                  16    position_source int     Origin of this state’s position: 0 = ADS-B, 1 = ASTERIX, 2 = MLAT
 
     *     cursor.execute("""CREATE TABLE IF NOT EXISTS flightdata
    (Time DATETIME, HexID text, Flag text, Callsign text,
    Registration text, Type text, Squawk integer,
    Altft integer, Spdkt integer, VRateftmin integer,
    Distnmi integer, Track float, Messages text, Seen
    integer, RSSI float, Latitude float, Longitude
    float, Source text, Mil text, WindD integer,
    Windkt integer)
    """)
     *  
        0: Time
        1: HexId
        2: Flag
        3: Callsign
        4: Registration
        5: Type 
        6: Squawk
        7: Altitude
        8: Speed
        9: Vertical Rate
        10: Distance
        11: Track
        12: Messages
        13: Seen
        14: RSSI
        15: Latitude
        16: Longitude
        17: Source
        18: Mil
        19: Wind Direction
        20: Wind Speed
        21: ICAO24
        22: Misc
        23: ModelName
     */

      if (tstamp !== rawData.Time) {
        data.chunks?.push([i - 1, i]);
        tstamp = rawData.Time;
      }

      // let altitude = rawData?.Altft < 0 ? 0 : ;

      const stateVector: IStateVector = {
        icao24: rawData?.HexID,
        callsign: rawData?.Callsign,
        origin_country: rawData?.Flag,
        time_position: rawData?.Time,
        last_contact: rawData?.Seen,
        longitude: rawData?.Longitude,
        latitude: rawData?.Latitude,
        baro_altitude: rawData?.Altft,
        on_ground: rawData?.Altft < 0,
        velocity: rawData?.Spdkt,
        true_track: rawData?.Track,
        vertical_rate: rawData?.VRateftmin,
        sensors: null,
        geo_altitude: rawData?.Altft,
        squawk: rawData?.Squawk,
        spi: true, // make conditional if extend to pedestreialn
        position_source: rawData?.Source,
        category: rawData?.Type,
        longname: rawData?.ModelName,
        registration: rawData?.Registration,
        typecode: rawData?.Type,
      };

      data.states.push(stateVector);
      i += 1;
    }

    return data;
  }, []);

  useEffect(() => {
    const fetchStates = () =>
      fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:5000/api/flightdata/readslice?start=202306011306&end=202306011506"
          : `${process.env.REACT_APP_SERVICE_URL}/flightdata/readslice?start=${startTime}&end=${endTime}`
      )
        .then((response) => response.json())
        .then((data) => {
          let mappedData = mapRawStateVectorData(data);
          setStateVectors(mappedData);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.current = false;
        });

    if (startTime && endTime && !loading.current) {
      loading.current = true;
      fetchStates();
    }
  }, [startTime, endTime, mapRawStateVectorData]);

  return (
    <FlightMap
      icaos={allIcaos}
      stateVectors={stateVectors}
      layerType={"PointCloudLayer"}
    />
  );
};
