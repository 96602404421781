/* eslint-disable */
import { AmbientLight, LightingEffect, PointLight } from "@deck.gl/core/typed";
import {
  LineLayerProps,
  PathLayerProps,
  PointCloudLayerProps,
} from "@deck.gl/layers/typed";
import { GeoJSON } from "geojson";
import { IStateVector, IStateVectorData } from "../types";

/**
 * Helpers
 *
 */
export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Constants
 */
export const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";

export const ANIMATION_SPEED = 500; // Change frequency in milliseconds
export const UNSELECTED_OPACITY = 20;
export const SELECTED_OPACITY = 255;
/**
 * Data & Prop Types
 */
export type LayerType = "PathLayer" | "LineLayer" | "PointCloudLayer";

export interface GenLayer {
  id: string;
  props: LineLayerProps | PathLayerProps | PointCloudLayerProps;
}

export interface IFlightMapProps {
  genLayers: GenLayer[] | undefined;
  layerType: LayerType;
  time: number;
  chunks: number[][] | undefined;
  states: Array<IStateVector>;
  names: Array<string> | undefined;
  is3DPointLayerEnabled: boolean;
  mBaseJson: GeoJSON | undefined;
  mapStyle?: string;
}

export interface IFlightMap {
  stateVectors: IStateVectorData;
  icaos: Array<string | undefined>;
  layerType: LayerType;
}

export type CoorData = {
  from: { coordinates: [number, number] };
  to: { coordinates: [number, number] };
};

/**
 * Styling Types
 */

export type TooltipContent =
  | null
  | string
  | {
      text?: string;
      html?: string;
      className?: string;
      style?: Partial<CSSStyleDeclaration>;
    };

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
});

const pointLight1 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-0.144528, 49.739968, 80000],
});

const pointLight2 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-3.807751, 54.104682, 8000],
});

const lightingEffect = new LightingEffect({
  ambientLight,
  pointLight1,
  pointLight2,
});

export const material: any = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
};

export const DEFAULT_THEME = {
  buildingColor: [74, 80, 87],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material,
  effects: [lightingEffect],
};

export const INITIAL_VIEW_STATE = {
  longitude: -1.415727,
  latitude: 52.232395,
  zoom: 6.6,
  minZoom: 4,
  pitch: 40.5,
  bearing: -27,
};
export const INITIAL_VIEW_STATE_3D = {
  longitude: -1.415727,
  latitude: 52.232395,
  zoom: 6.6,
  minZoom: 4,
  pitch: 40.5,
  bearing: -27,
};


export const colorRange: any = [
  [1, 152, 189],
  [73, 227, 206],
  [216, 254, 181],
  [254, 237, 177],
  [254, 173, 84],
  [209, 55, 78],
];

// default visible spectrum
export const DEFAULT_COLOR_RANGE: [number, number, number, number][] = [
  [255, 0, 255, 255],
  [0, 0, 255, 255],
  [0, 255, 0, 255],
  [255, 255, 0, 255],
  [255, 0, 0, 255],
];

// Violet color with increaing alpha
export const VIOLET_COLOR_GRADIENT: [number, number, number, number][] = [
  [255, 0, 255, 0.2 * 255],
  [255, 0, 255, 0.4 * 255],
  [255, 0, 255, 0.6 * 255],
  [255, 0, 255, 0.8 * 255],
  [255, 0, 255, 255],
];

export const RED_COLOR_GRADIENT: [number, number, number, number][] = [
  [255, 0, 0, 0.5 * 255],
  [255, 0, 0, 0.55 * 255],
  [255, 0, 0, 0.6 * 255],
  [255, 0, 0, 0.65 * 255],
  [255, 0, 0, 0.7 * 255],
];

export const GREEN_COLOR_GRADIENT: [number, number, number, number][] = [
  [0, 255, 0, 0.5 * 255],
  [0, 255, 0, 0.55 * 255],
  [0, 255, 0, 0.6 * 255],
  [0, 255, 0, 0.865 * 255],
  [0, 255, 0, 0.7 * 255],
];

export const BLUE_COLOR_GRADIENT: [number, number, number, number][] = [
  [0, 0, 255, 0.5 * 255],
  [0, 0, 255, 0.55 * 255],
  [0, 0, 255, 0.6 * 255],
  [0, 0, 255, 0.65 * 255],
  [0, 0, 255, 0.7 * 255],
];

export const POINTLAYER_3D_COLORS = [
  RED_COLOR_GRADIENT,
  GREEN_COLOR_GRADIENT,
  BLUE_COLOR_GRADIENT,
];
