import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DismissingAlert from "../common/mui-packages/src/layouts/sections/attention-catchers/alerts/components/DismissingAlert/index.js";
import { SignInBasic } from "../common/mui-packages/src/pages/Authentication/SignIn/Basic/SignInBasic";
import { AppContext } from "../contexts/AppContextProvider";
import { Paths } from "../routes/paths";

export const LoginPage: React.FC = () => {

    const navigate = useNavigate();

    const { checkAuth } = useContext(AppContext);


    function onTrySigin(user: string, pass: string) {
        // console.log("onTrySigin");
       if ( checkAuth(user, pass)){
              navigate(Paths.map);

       }
       else {
        // console.log("Failure");

        DismissingAlert("Wrong sign in credentials Richard");
       }
    };




    return <SignInBasic trySignIn={onTrySigin} />;
    }

