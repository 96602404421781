/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TimeContext } from "../contexts";

import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";

import dayjs from "dayjs";
import { MapView } from "../components/MapView";
import { MapLayerContext } from "../contexts/MapLayerContextProvider";


enum TimeRangeEnumeration {
  UNSET = "UNSET",
  ONE_HOUR = "ONE_HOUR",
  TWELVE_HOURS = "TWELVE_HOURS",
  TWENTYFOUR_HOURS = "TWENTYFOUR_HOURS",
}

type Value = Date | Date[] | null;
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StartPage: React.FC = () => {
  const {
    startTimeLocal,
    endTimeLocal,
    startTimeUtcString,
    endTimeUtcString,
    setTimeRange,
  } = useContext(TimeContext);

  const { set3DPointLayer, is3DPointLayer } = useContext(MapLayerContext);
  const [buttonTimeRange, setButtonTimeRange] = useState<TimeRangeEnumeration>(
    TimeRangeEnumeration.UNSET
  );

  const [isViewDialogVisible, setViewDialogVisible] = useState(false);

  // default to 1 hour view on start
  useEffect(() => {
    setHourTimeRange(1);
    setButtonTimeRange(TimeRangeEnumeration.ONE_HOUR);
  }, []);



  const setHourTimeRange = (hours: number) => {
    let now = dayjs();
    let minutes = hours * 60;
    let lastHour = now.subtract(minutes, "minutes");
    setTimeRange(lastHour.toDate(), now.toDate());
  };

  const enable3DPointLayer = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      set3DPointLayer(checked);
    },
    [set3DPointLayer]
  );

  return (
      <MapView startTime={startTimeUtcString} endTime={endTimeUtcString} />
  );
};

export default StartPage;
